export const realtorData = {
  email: 'royalbrokers@unne.cl',
  phone:'56949993451'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'royalbrokers@unne.cl',
  phone:'949993451',
  wsp:'949993451',
}
